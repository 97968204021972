import {UUID_REGEX} from '@imt/vue-toolbox/src/utils';
export default [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "VBase" */ '@/views/VBase.vue'),
        children: [
            {
                path: '',
                redirect: {
                    name: 'set.list',
                },
            },
            {
                path: 'sets',
                name: 'set.list',
                meta: {
                    title: 'Sets',
                },
                component: () => import(/* webpackChunkName: "VSetList" */ '@/views/VSetList.vue'),
                children: [
                    {
                        path: 'new',
                        name: 'set.add',
                        component: () => import(/* webpackChunkName: "VSetAddModal" */ '@/views/VSetAddModal.vue'),
                    },
                    {
                        path: `/options/:setId(${UUID_REGEX})`,
                        name: 'set.edit',
                        component: () => import(/* webpackChunkName: "VSetOptionsModal" */ '@/views/VSetOptionsModal.vue'),
                    },
                ],
            },
            {
                path: `sets/:setId(${UUID_REGEX})`,
                name: 'set.detail',
                component: () => import(/* webpackChunkName: "VSetDetail" */ '@/views/VSetDetail.vue'),
                children: [
                    {
                        path: 'subscriptions',
                        name: 'set.subscriptions',
                        component: () => import(/* webpackChunkName: "VSubscriptionsModal" */ '@/views/VSubscriptionsModal.vue'),
                    },
                    {
                        path: 'tasks/new',
                        name: 'set.task.add',
                        component: () => import(/* webpackChunkName: "VTaskAddModal" */ '@/views/VTaskAddModal.vue'),
                    },
                    {
                        path: `tasks/:taskId(${UUID_REGEX})`,
                        name: 'set.task.edit',
                        component: () => import(/* webpackChunkName: "VTaskOptionsModal" */ '@/views/VTaskOptionsModal.vue'),
                    },
                    {
                        path: `tasks/:taskId(${UUID_REGEX})/notes`,
                        name: 'set.task.notes',
                        component: () => import(/* webpackChunkName: "VTaskNotesModal" */ '@/views/VTaskNotesModal.vue'),
                    },
                ],
            },
            {
                path: `sets/:setId(${UUID_REGEX})/activity`,
                name: 'set.activity',
                meta: {
                    title: 'Set Activity',
                },
                component: () => import(/* webpackChunkName: "VSetActivityList" */ '@/views/VSetActivityList.vue'),
                children: [],
            },
        ],
    },
    {
        path: '/admin',
        component: () => import(/* webpackChunkName: "VBase" */ '@/views/VBase.vue'),
        children: [
            {
                path: '',
                name: 'admin',
                redirect: {
                    name: 'admin.predefined-set.list',
                },
            },
            {
                path: 'predefined-sets',
                name: 'admin.predefined-set.list',
                meta: {
                    title: 'Predefined Sets',
                },
                component: () => import(/* webpackChunkName: "VPredefinedSetList" */ '@/views/VPredefinedSetList.vue'),
                children: [
                    {
                        path: 'new',
                        name: 'admin.predefined-set.add',
                        component: () => import(/* webpackChunkName: "VPredefinedSetAddModal" */ '@/views/VPredefinedSetAddModal.vue'),
                    },
                ],
            },
            {
                path: `predefined-sets/:setId(${UUID_REGEX})`,
                name: 'admin.predefined-set.detail',
                meta: {
                    title: 'Edit Predefined Set',
                },
                component: () => import(/* webpackChunkName: "VPredefinedSetDetail" */ '@/views/VPredefinedSetDetail.vue'),
                children: [
                    {
                        path: 'tasks/new',
                        name: 'admin.predefined-set.task.add',
                        component: () => import(/* webpackChunkName: "VTaskAddModal" */ '@/views/VTaskAddModal.vue'),
                    },
                    {
                        path: `tasks/:taskId(${UUID_REGEX})`,
                        name: 'admin.predefined-set.task.edit',
                        component: () => import(/* webpackChunkName: "VTaskOptionsModal" */ '@/views/VTaskOptionsModal.vue'),
                    },
                    {
                        path: `tasks/:taskId(${UUID_REGEX})/notes`,
                        name: 'admin.predefined-set.task.notes',
                        component: () => import(/* webpackChunkName: "VTaskNotesModal" */ '@/views/VTaskNotesModal.vue'),
                    },
                ],
            },
        ],
    },
    // Fallback
    {
        path: '*',
        name: 'not-found',
        meta: {
            title: 'Not Found',
        },
        component: () => import(/* webpackChunkName: "KCNotFound" */ '@imt/vue-kit-car/src/components/errors/NotFound.vue'),
    },
];
