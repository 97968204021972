<template>
    <div id="app">
        <div
            v-if="permissionsLoaded && hasAccess"
            class="container-fluid"
        >
            <div class="row flex-nowrap">
                <div class="col-auto px-0">
                    <Menu :default-menu="null">
                        <template #section-one-heading="{collapsed}">
                            <MenuHeading
                                title="Vinna"
                                :icon="['far', 'clipboard-list-check']"
                                :collapsed="collapsed"
                            />
                        </template>

                        <template #section-one-items="{collapsed}">
                            <MenuItem
                                :to="{name: 'set.list'}"
                                icon="list"
                                :collapsed="collapsed"
                                title="Sets"
                            />

                            <MenuItem
                                v-if="canManagePredefined"
                                :to="{name: 'admin.predefined-set.list'}"
                                icon="cogs"
                                :collapsed="collapsed"
                                title="Predefined Sets"
                            />
                        </template>

                        <template #section-two-heading="{collapsed}">
                            <MenuHeading
                                title="IMT Platform"
                                icon="cube"
                                :collapsed="collapsed"
                            />
                        </template>

                        <template #section-two-items="{collapsed}">
                            <MenuItem
                                v-for="item in platformMenuItems"
                                :key="item.title"
                                :href="item.href"
                                :icon="item.icon"
                                :collapsed="collapsed"
                                :title="item.title"
                            />
                        </template>
                    </Menu>
                </div>

                <div class="col px-0 d-flex flex-column">
                    <Header
                        :title="pageTitle"
                    />

                    <RouterView />

                    <div class="row footer m-0 mt-auto">
                        <div class="col-4">
                            <a
                                href="https://ticket.imtins.com/status/endpoints"
                                class="btn btn-link p-0"
                            >
                                Platform Status
                            </a>
                        </div>

                        <div class="col text-muted text-center">
                            <span class="copyright">
                                &copy;{{ copyrightText }}
                            </span>
                        </div>

                        <div class="col-4 text-right">
                            <button
                                ref="backToTop"
                                v-scroll-to="'#app'"
                                class="btn btn-link btn-back-to-top p-0"
                            >
                                <FontAwesomeIcon
                                    icon="caret-up"
                                />

                                Back to top
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Forbidden
            v-else-if="permissionsLoaded && !hasAccess"
            class="vh-100 justify-content-center"
        />
    </div>
</template>

<script>
    import Header from '@imt/vue-admin-menus/src/components/AMHeader.vue';
    import Menu from '@imt/vue-admin-menus/src/components/AMMenu.vue';
    import MenuHeading from '@imt/vue-admin-menus/src/components/AMMenuHeading.vue';
    import MenuItem from '@imt/vue-admin-menus/src/components/AMMenuItem.vue';
    import platformAdminMenuItems from '@imt/vue-admin-menus/src/menus';
    import modeMixin from '@imt/vue-kit-car/src/mixins/modes';
    import authMixin from '@imt/vue-toolbox/src/mixins/auth';
    import dayjs from 'dayjs';
    import {mapMutations, mapState} from "vuex";

    import permissionsMixin from '@/mixins/permissions';


    export default {
        name: 'App',
        components: {
            Header,
            Menu,
            MenuHeading,
            MenuItem,
            Forbidden: () => import(/* webpackChunkName: "KCForbidden" */ '@imt/vue-kit-car/src/components/errors/Forbidden.vue'),
        },
        mixins: [
            authMixin,
            modeMixin,
            permissionsMixin,
        ],
        metaInfo() {
            return {
                title: '',
                titleTemplate: 'Vinna | IMT Insurance',
            };
        },
        computed: {
            copyrightText: () => `Copyright ${dayjs().year()} IMT Insurance. All rights reserved.`,
            darkMode() {
                return this.mode === 'dark';
            },
            pageTitle() {
                return this.user.uuid ? this.hasAccess
                    ? this.pageHeaderTitle || ''
                    : 'Permission Denied'
                    : 'Loading';
            },
            platformMenuItems() {
                return platformAdminMenuItems().filter(item => {
                    return item.section === 1 && item.title.toLowerCase() !== 'workflow';
                });
            },
            ...mapState(['pageHeaderTitle']),
        },
        watch: {
            $route(to) {
                if (to.meta?.title) {
                    this.SET_DATA({field: 'pageHeaderTitle', data: to.meta.title});
                } else {
                    let parent = this.$route.matched.map(route => {
                        return route.meta.title;
                    }).filter(route => route !== undefined);

                    if (parent.length) {
                        this.SET_DATA({field: 'pageHeaderTitle', data: parent[parent.length - 1]});
                    }
                }
            },
        },
        methods: {
            ...mapMutations(['SET_DATA'])
        },
    };
</script>

<style lang="sass">
    @import "~@imt/vue-kit-car/src/sass/kit-car"
</style>
