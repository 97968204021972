import {mapActions, mapState} from 'vuex';

export default {
    computed: {
        canManage() {
            return !!this.permissions.set_manage;
        },
        canManagePredefined() {
            return !!this.isAdmin;
        },
        hasAccess() {
            return !!this.permissions.vinna_access;
        },
        isAdmin() {
            return !!this.permissions.vinna_admin;
        },
        permissionsLoaded() {
            return !!this.user.uuid;
        },
        ...mapState('toolbox', [
            'user',
            'permissions',
        ]),
    },
    methods: {
        ...mapActions([
            'fetchUsers',
        ]),
        ...mapActions('toolbox', [
            'fetchUser',
        ]),
    },
};
