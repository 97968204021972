import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {extend} from 'vee-validate';
import {required, max} from 'vee-validate/dist/rules';
import Vue from 'vue';

extend('required', {
    ...required,
    message: 'This field is required',
});

extend('max', {
    ...max,
    message: 'Set title cannot be longer than 100 characters',
});

extend('has_true', {
    validate: (value1, {
        value2,
        isRequired = true,
    }) => {
        return isRequired ? value1 || value2 : true;
    },
    params: ['value1', 'value2', 'isRequired'],
    message: 'This field is required',
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
