import notifications from '@imt/vue-admin-menus/src/store/notifications';
import {asString} from '@imt/vue-list/src/utils/queryParams';
import toolbox from '@imt/vue-toolbox/src/store';
import utils, {axiosWithAuth} from '@imt/vue-toolbox/src/utils';
import camelCase from 'lodash/camelCase';
import kebabCase from 'lodash/kebabCase';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const authedAxios = axiosWithAuth(),
    resourceHosts = {
        'action bar': {
            host: process.env.VUE_APP_ADMIN_URL_CMS,
            adminPath: 'admin/policy-system/<polId>/action-bars/<id>',
            path: 'action-bar',
            qp: '?page[size]=1000',
        },
        'data map': {
            host: process.env.VUE_APP_ADMIN_URL_MAPPING,
            adminPath: 'admin/datamaps/<id>',
            path: 'datamaps',
            qp: '?include=versions&page[size]=1000',
        },
        'navigation item': {
            host: process.env.VUE_APP_ADMIN_URL_CMS,
            adminPath: 'admin/policy-system/<polId>/navigation',
            path: 'navigation-item',
            qp: '?page[size]=1000',
        },
        'policy system': {
            host: process.env.VUE_APP_ADMIN_URL_CMS,
            adminPath: 'admin/policy-systems/<id>',
            path: 'policy-system',
            qp: '?page[size]=1000',
        },
        'question group': {
            host: process.env.VUE_APP_ADMIN_URL_CMS,
            adminPath: 'admin/policy-system/<polId>/question-groups/<id>',
            path: 'question-groups',
            qp: '?include=versions&page[size]=1000',
        },
        company: {
            host: process.env.VUE_APP_ADMIN_URL_PLATFORM_ADMIN,
            adminPath: 'admin/companies/<id>',
            path: 'company',
            qp: '?page[size]=1000',
        },
        form: {
            host: process.env.VUE_APP_ADMIN_URL_FORMS,
            adminPath: 'admin/forms/<id>',
            path: 'forms',
            qp: '?include=versions&page[size]=1000',
        },
        page: {
            host: process.env.VUE_APP_ADMIN_URL_CMS,
            adminPath: 'admin/policy-system/<polId>/pages/<id>',
            path: 'page',
            qp: '?include=versions&page[size]=1000',
        },
        process: {
            host: process.env.VUE_APP_BEHANDLE_URL,
            adminPath: 'admin/processes/<id>',
            path: 'processes',
            qp: '?include=versions&page[size]=1000',
        },
        rule: {
            host: process.env.VUE_APP_ADMIN_URL_BUSINESS_RULES,
            adminPath: 'admin/rules/<id>',
            path: 'rule',
            qp: '?include=versions&page[size]=1000',
        },
    };

export const getters = {
    defaultSubscriptions(state) {
        let userIsOwner = state.set.ownerUuid == state.toolbox.user.uuid; // eslint-disable-line eqeqeq

        return {
            assigned_to_task: {
                email: true,
                platform: true,
                label: `Notify me when I'm assigned to a task.`,
            },
            task_assignee_changed: {
                email: true,
                platform: true,
                label: `Notify me if a task I'm assigned to is assigned to someone else.`,
            },
            task_set_to_start: {
                email: false,
                platform: true,
                label: `Notify me if a task I'm assigned to should start based on the Start Date.`,
            },
            task_status_changed: {
                email: false,
                platform: userIsOwner,
                label: 'Notify me when the status of a task changed.',
            },
            task_marked_unassigned: {
                email: false,
                platform: userIsOwner,
                label: `Notify me when a task's assignee in the set is marked unassigned.`,
            },
            task_added: {
                email: false,
                platform: userIsOwner,
                label: 'Notify me when a task is added to the set.',
            },
            task_deleted: {
                email: false,
                platform: userIsOwner,
                label: 'Notify me when a task is deleted from the set.',
            },
            set_status_changed: {
                email: false,
                platform: false,
                label: 'Notify me when the set status changes.',
            },
        };
    },
};

export const actions = {
    async deleteSet(_, {
        id,
        isPredefined = false,
    }) {
        await authedAxios.delete(`/${isPredefined ? 'predefined-sets' : 'sets'}/${id}/`);
    },
    async fetchActivity(context, {
        filters = {},
        limit = null,
        page = null,
    }) {
        let url = `${process.env.VUE_APP_ADMIN_URL_USERS}/api/v1/activity/?include=user`;

        url += `${Object.keys(filters).map(k => `&filter[${k}]=${filters[k]}`).join('')}`;
        url += page ? `&page=${page}` : '';
        url += limit ? `&page[size]=${limit}` : '';

        const response = await authedAxios.get(url);

        let activity = utils.dataFormatter.deserialize(response.data),
            paging = response.data.meta.pagination;

        paging.pageSize = response.data.data.length;
        paging.links = response.data.links;

        return {
            activity,
            paging,
        };
    },
    async fetchResources(_, resource) {
        const urlData = resourceHosts[resource],
            response = await authedAxios.get(`${urlData.host}/api/v1/${urlData.path}/${urlData.qp}`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchItems(_, params = '') {
        const response = await authedAxios.get(`/items/?include=task${params}`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchSet({commit}, {
        id,
        isPredefined = false,
    }) {
        const urlPart = isPredefined ? 'predefined-set' : 'set',
            response = await authedAxios.get(`/${urlPart}s/${id}/?include=created_by,updated_by`);

        commit('SET_DATA', {
            field: camelCase(urlPart),
            data: utils.dataFormatter.deserialize(response.data),
        });

        return response;
    },
    async fetchSets({commit}, {isPredefined = false, queryObject = {}} = {}) {
        let urlPart = isPredefined ? 'predefined-set' : 'set',
            queryString = asString(queryObject);

        const response = await authedAxios.get(`/${urlPart}s/?include=${isPredefined ? 'predefinedTasks' : 'tasks'}${queryString}`);

        commit('SET_DATA', {
            field: `${camelCase(urlPart)}s`,
            data: utils.dataFormatter.deserialize(response.data),
        });

        commit('SET_DATA', {field: 'totalItemCount', data: response.data.meta.pagination.count});

        return response;
    },
    async fetchTask({commit}, {id, isPredefined = false}) {
        const urlPart = isPredefined ? 'predefined-task' : 'task',
            response = await authedAxios.get(`/${urlPart}s/${id}/?include=${isPredefined ? '' : 'items,created_by,updated_by'}`);

        commit('SET_DATA', {
            field: camelCase(urlPart),
            data: utils.dataFormatter.deserialize(response.data),
        });

        return response;
    },
    async deleteTask({state, commit}, {id, isPredefined = false}) {
        const fieldName = isPredefined ? 'predefinedTasks' : 'tasks';

        await authedAxios.delete(`/${kebabCase(fieldName)}/${id}/`);

        commit('REMOVE_LIST_ITEM', {
            fieldName,
            fieldIndex: state[fieldName].findIndex(i => i.id.toString() === id.toString()),
        });
    },
    async fetchTasks({commit, state}, {isPredefined = false, filter = '', queryString = '', setId, url} = {}) {
        let urlPart = isPredefined ? 'predefined-task' : 'task',
            tasks = [];

        if (url) {
            url = url.replace(/.*\/api\/v1/, '');
            tasks = isPredefined ? state.predefinedTasks : state.tasks;
        }

        const response = await authedAxios.get(
            url || `/${urlPart}s/?filter[${isPredefined ? 'predefined_' : ''}set_id]=${setId}&include=${isPredefined ? '' : 'items'}${filter ? `&search=${filter}` : ''}${queryString}`,
        );

        commit('SET_DATA', {
            field: `${camelCase(urlPart)}s`,
            data: tasks.concat(utils.dataFormatter.deserialize(response.data)),
        });

        return response;
    },
    async fetchSubscriptions(context, query = '') {
        const response = await authedAxios.get(`/subscriptions/?${query}`),
            formattedData = utils.dataFormatter.deserialize(response.data);

        context.commit('SET_DATA', {
            field: 'subscriptions',
            data: formattedData,
        });

        return formattedData;
    },
    async fetchUsers({commit, state}, {query = ''} = {}) {
        if (state.internalUsers.length) {
            return;
        }

        if (process.env.NODE_ENV === 'test') {
            commit('SET_DATA', {
                field: 'internalUsers',
                data: [state.toolbox.user],
            });

            return;
        }

        const response = await authedAxios.get(`${process.env.VUE_APP_ADMIN_URL_USERS}/api/v1/users/?${query}`);

        commit('SET_DATA', {
            field: 'internalUsers',
            data: utils.dataFormatter.deserialize(response.data),
        });
    },
    async loadResourceData(_, item) {
        const urlData = resourceHosts[item.itemType],
            response = await authedAxios.get(`${urlData.host}/api/v1/${urlData.path}/${item.itemId}/${urlData.qp}`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async saveResource({state, commit}, {data, method = 'post', resourceType = 'Set', url = '', deserialize = true}) {
        let response = await authedAxios[method.toLowerCase()](
                `/${kebabCase(resourceType)}s/${url}`,
                utils.dataFormatter.serialize({
                    stuff: {
                        ...data,
                        type: resourceType,
                    },
                }),
            ),
            formattedData = {};

        if (deserialize) {
            formattedData = utils.dataFormatter.deserialize(response.data);
            const fieldName = camelCase(resourceType);

            if (Array.isArray(state[`${fieldName}s`])) {
                commit('APPEND_REPLACE_LIST_ITEM', {
                    fieldName: `${fieldName}s`,
                    item: formattedData,
                });
            }

            commit('SET_DATA', {
                field: fieldName,
                data: formattedData,
            });
        }

        return formattedData;
    },
    async fetchPolicySystem(context, policySystemId) {
        let response = await authedAxios.get(`${process.env.VUE_APP_ADMIN_URL_CMS}/api/v1/policy-systems/${policySystemId}/`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchPolicies(context, policyNumber) {
        let response = await authedAxios.get(`${process.env.VUE_APP_POLICY_DATA_URL}/api/v1/policies/?filter[number]=${policyNumber}&include=versions&sort=-versions__term_effective_date`);

        return utils.dataFormatter.deserialize(response.data);
    },
};

export const mutations = {
    APPEND_REPLACE_LIST_ITEM(state, {fieldName, item}) {
        let itemIndex = state[fieldName].findIndex(i => i.id.toString() === item.id.toString());

        if (itemIndex >= 0) {
            state[fieldName][itemIndex] = item;
        } else {
            state[fieldName].push(item);
        }
    },
    CLEAR_SEARCH_FIELDS(state) {
        state.searchFields = {};
    },
    REMOVE_LIST_ITEM(state, {fieldName, fieldIndex}) {
        if (fieldIndex >= 0) {
            state[fieldName].splice(fieldIndex, 1);
        }
    },
    SET_DATA(state, {field, data}) {
        state[field] = data;
    },
};

export const state = () => {
    return {
        duplicateTask: {},
        internalUsers: [],
        predefinedSet: {},
        predefinedSets: [],
        predefinedTask: {},
        predefinedTasks: [],
        duplicatePredefinedSet: {},
        saveAsPredefinedSet: {},
        searchFields: {},
        set: {},
        sets: [],
        subscription: {},
        subscriptions: [],
        task: {},
        tasks: [],
        totalItemCount: 0,
        pageHeaderTitle: '',
    };
};

export default new Vuex.Store({
    modules: {
        notifications,
        toolbox,
    },
    actions,
    getters,
    mutations,
    state: state(),
});
