import BootstrapVue from 'bootstrap-vue';
import Vue from 'vue';
import VueMeta from 'vue-meta';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@/plugins/font-awesome';
import '@/plugins/scroll-to';
import '@/plugins/sentry';
import '@/plugins/vee-validate';

Vue.use(BootstrapVue);
Vue.use(VueMeta);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
