import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faCheckSquare,
    faClipboardListCheck,
    faClone,
    faCopy,
    faEnvelope,
    faExternalLink,
    faFileAlt,
    faFilter,
    faShareAlt,
    faSquare,
    faStar,
    faSave,
    faTimes,
    faTrashAlt,
    faUserCheck,
    faUserClock,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faAngleLeft,
    faBadgeCheck,
    faBell,
    faCaretUp,
    faChartLine,
    faCheck,
    faCheckCircle,
    faClock,
    faCog,
    faCogs,
    faEllipsisV,
    faExclamationTriangle,
    faList,
    faListAlt,
    faPause,
    faPen,
    faPlus,
    faSpinner,
    faTable,
    faTasks,
    faVial,
} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

library.add(
    faAngleLeft,
    faBadgeCheck,
    faBell,
    faCaretUp,
    faChartLine,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faClipboardListCheck,
    faClock,
    faClone,
    faCog,
    faCogs,
    faCopy,
    faEllipsisV,
    faEnvelope,
    faExclamationTriangle,
    faExternalLink,
    faFileAlt,
    faFilter,
    faList,
    faListAlt,
    faPause,
    faPen,
    faPlus,
    faSave,
    faShareAlt,
    faSpinner,
    faSquare,
    faStar,
    faTable,
    faTasks,
    faTimes,
    faTrashAlt,
    faUserCheck,
    faUserClock,
    faVial,
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
